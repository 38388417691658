import React from 'react'
import { Link, graphql } from 'gatsby'

import Environment from '../../lib/Environment'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import CopyContainer from '../../components/CopyContainer'

import '../index.scss'


class Suppliers extends React.Component {

  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const content = data.markdownRemark

    return (
      <Layout root={true} location={this.props.location} title={siteTitle}>
        <SEO
          title="Suppliers"
        />
      <CopyContainer>
        <h1>
          {content.frontmatter.title}
        </h1>
        <p>
          Effective: {content.frontmatter.effectiveDate}
        </p>
        <div className="CopyContainer-body" dangerouslySetInnerHTML={{ __html: content.html }} />
      </CopyContainer>
      </Layout>
    )
  }
}

export default Suppliers

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/legal/suppliers" } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        effectiveDate(formatString: "D MMMM YYYY")
      }
    }
  }
`
